<template>
  <div>
    <v-btn color="red" small @click="dialogDisableTechnician = true">
      <v-icon color="white" small>clear</v-icon>
    </v-btn>

    <!-- Dialog cancel project -->
    <DialogBox :model="dialogDisableTechnician" :color="'error'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de deshabilitar este técnico?</span>
      </template>
      <template slot="content">
        <v-form ref="formDisableTechnician" v-model="formDisableTechnicianValid">
          <v-text-field v-model="technician_id" label="ID del técnico" :rules="formRules.textRules" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogDisableTechnician = false">Cerrar</v-btn>
        <v-btn text :disabled="technician_id !== technician_data.id" @click="disableTechnician()">Deshabilitar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DialogBox from "@/components/elements/DialogBox";

export default {
  components: {
    DialogBox
  },
  mixins: [FormRulesMixin],
  props: {
    technician_data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogDisableTechnician: false,
      formDisableTechnicianValid: false,
      technician_id: ""
    };
  },
  methods: {
    async disableTechnician() {
      if (this.$refs.formDisableTechnician.validate()) {
        await this.$store.dispatch("technicians/disableTechnician", this.technician_data.id);

        this.dialogDisableTechnician = false;
        this.technician_id = "";
      }
    }
  }
};
</script>
